
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/available-now",
      function () {
        return require("private-next-pages/available-now/index.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/available-now"])
      });
    }
  